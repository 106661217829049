import firebase from "firebase";
import { User } from "@/entities/user";

export async function getAllLoginTutors(): Promise<User[]> {
  const apiGetAllLoginTutors = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_all_login_tutors");
  const res = await apiGetAllLoginTutors();
  return res.data.users;
}
